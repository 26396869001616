import { Form, Button, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";
import LoggedHeader from "./componenets/loggedHeader";

const CreateSupportTicket = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<any>([]);
  const { run, loading } = useApi();

  const createTicket = async (values: any) => {
    try {
      const response = await run(
        axiosInstance.post("/customer/support/createTicket", values)
      );
      notification.success({
        message: `Problem reported successfully!`,
      });

      navigate("/");
    } catch (e: any) {
      console.log(e.response.data.errors);
      notification.error({
        message: e.response.data.errors.map((el: any) => el.message).join(","),
      });
    }
  };

  const { TextArea } = Input;

  return (
    <>
      <LoggedHeader />

      <div className="p-10 bg-white">
        <Form layout="vertical" onFinish={createTicket}>
          <Form.Item label="Problem type" name={"type"} required={true}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Issue"
            name={"issue"}
            rules={[
              {
                required: true,
                message: "Chaiyo",
              },
            ]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            label="Login code"
            name={"priority"}
            hidden
            initialValue={"Medium"}
          >
            <Input />
          </Form.Item>

          <Button
            type="default"
            htmlType="button"
            onClick={() => {
              navigate("/");
            }}
            className="mr-2"
          >
            Cancel
          </Button>

          <Button
            type="primary"
            className="text-white bg-red-600"
            loading={loading}
            htmlType="submit"
          >
            Report issue
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CreateSupportTicket;
