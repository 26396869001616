import { useState } from "react";
const useApi = () => {
  const [loading, setLoading] = useState(false);

  const run = async (query: any) => {
    try {
      setLoading(true);
      const response = await query;
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  return { loading, setLoading, run };
};

export default useApi;
