import { Button, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

const LoggedHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#08787f] p-5 text-white font-semibold">
      <div className="flex items-center justify-between">
        <div>Customer Support Portal</div>

        <div className="flex gap-2">
          <Link to="/create-support-ticket">
            <Button className="!bg-white !text-black">
              {" "}
              + Create support ticket
            </Button>
          </Link>
          <Popconfirm
            title={"Are you sure?"}
            okButtonProps={{ style: { background: "red" } }}
            onConfirm={() => {
              localStorage.removeItem("customerAccessToken");
              navigate("/");
            }}
          >
            {" "}
            <span
              onClick={() => {
                // localStorage.removeItem("customerAccessToken");
                // navigate("/");
              }}
            >
              <Button className="!bg-red-600 !text-white">
                <FaPowerOff />
              </Button>
            </span>{" "}
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default LoggedHeader;
