import { Form, Button, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";
import LoggedHeader from "./componenets/loggedHeader";

const TicketView = () => {
  const [ticket, setTicket] = useState<any>({});

  const navigate = useNavigate();

  const param = useParams();

  const { run, loading } = useApi();

  const getTicket = async () => {
    try {
      const response = await run(
        axiosInstance.get("/customer/support/getTickets?_id=" + param.ticket_id)
      );
      setTicket(response.data.data[0]);
    } catch (e: any) {
      console.log(e.response.data.errors);
      notification.error({
        message: e.response.data.errors.map((el: any) => el.message).join(","),
      });
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  return (
    <>
      <LoggedHeader />

      <div
        className="p-4 bg-white cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        {"<"} Back
      </div>

      <div className="p-10 bg-white">
        <h1>#{ticket.ticket_id}</h1>
        <b>{ticket.ticket_type}</b>
        <br />
        <b>{ticket.problem_reported}</b>
      </div>
    </>
  );
};

export default TicketView;
