import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";

const VersionCheck = () => {
  const [newVersion, setNewVersion] = useState(false);

  const latest_version = 1.0;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams && searchParams.get("v")) {
      const getVersion = parseFloat(searchParams!.get("v")!.toString());
      console.log(getVersion, latest_version);
      if (getVersion < latest_version) {
        // setNewVersion(true);
        window.location.href = "http://csp.dynohive.com/updateRequired";
      } else {
        navigate("/");
      }
    } else {
      setNewVersion(true);
    }
  }, []);

  return (
    <>
      {newVersion && (
        <>
          Update available{" "}
          <button
            onClick={() => {
              try {
                window.location.href = "http://csp.dynohive.com/updates";
              } catch (e) {}
            }}
          >
            Download
          </button>
        </>
      )}
    </>
  );
};

export default VersionCheck;
