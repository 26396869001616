import { Form, Button, Input, notification, Tag } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";
import LoggedHeader from "./componenets/loggedHeader";

const Dashboard = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<any>([]);
  const { run, loading } = useApi();

  const getTickets = async () => {
    try {
      const response = await run(
        axiosInstance.get("/customer/support/getTickets")
      );
      setTickets(response.data.data);
    } catch (e: any) {
      console.log(e.response.data.errors);
      notification.error({
        message: e.response.data.errors.map((el: any) => el.message).join(","),
      });
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <>
      <LoggedHeader />

      <div className="p-10 bg-white">Welcome to Customer Support Portal </div>

      <div className="p-10 bg-white">
        <span className="font-semibold ">Your support tickets:</span> <br />{" "}
        <br />
        {tickets.map((el: any) => (
          <>
            <Link to={`/view-ticket/${el._id}`}>
              <div className="bg-red-50 p-5 rounded mt-2 shadow">
                <h1 className="font-semibold ">#{el.ticket_id}</h1>
                {el.ticket_type}
                <br />
                {el.problem_reported}
                <br />
                <Tag color="red">{el.ticket_status}</Tag>
              </div>
            </Link>
          </>
        ))}
      </div>
    </>
  );
};

export default Dashboard;
