import axios from "axios";
import { base_url } from "./appConstants";

const axiosInstance = axios.create({
  baseURL: base_url,
});

const requestMiddleware = (config: any): any => {
  const accessToken = localStorage.getItem("customerAccessToken");

  if (accessToken && accessToken.length > 1) {
    config.headers!["Authorization"] = "Bearer " + accessToken;
  }
  return config;
};

axiosInstance.interceptors.request.use(requestMiddleware);

export default axiosInstance;
