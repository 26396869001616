import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Main from "./Main";
import Login from "./Login";
import Dashboard from "./Dashboard";
import CreateSupportTicket from "./CreateSupportTicket";
import TicketView from "./TicketView";
import VersionCheck from "./VersionCheck";
import Updates from "./Updates";

function App() {
  return (
    <>
      <div className="max-w-3xl m-auto">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/updates" element={<Updates />}></Route>
            <Route path="/versionCheck" element={<VersionCheck />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route
              path="/create-support-ticket"
              element={<CreateSupportTicket />}
            ></Route>

            <Route
              path="/view-ticket/:ticket_id"
              element={<TicketView />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
