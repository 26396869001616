import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";

const Main = () => {
  const navigate = useNavigate();
  const { run, loading } = useApi();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("m") && searchParams.get("c")) {
      login();
    } else {
      if (localStorage.getItem("customerAccessToken")) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }
  }, []);

  const login = async () => {
    try {
      const response = await run(
        axiosInstance.post("/customer/login", {
          mobile: searchParams.get("m"),
          login_code: searchParams.get("c"),
        })
      );

      localStorage.setItem(
        "customerAccessToken",
        response.data.customerAccessToken
      );
      navigate("/dashboard");
    } catch (e: any) {
      navigate("/login");
    }
  };

  return <></>;
};

export default Main;
