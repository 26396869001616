import { Form, Button, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import useApi from "./utils/useApi";

const Login = () => {
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState<any>([]);
  const { run, loading } = useApi();

  const login = async (values: any) => {
    try {
      const response = await run(axiosInstance.post("/customer/login", values));

      console.log(response.data);
      localStorage.setItem(
        "customerAccessToken",
        response.data.customerAccessToken
      );

      // if ((window as any).NATIVE_CHANNEL) {
      //   (window as any).NATIVE_CHANNEL.postMessage(
      //     JSON.stringify({ type: "loginSuccess", topics: ["APPLE"] })
      //   );
      // }

      navigate("/");
    } catch (e: any) {
      console.log(e.response.data.errors);
      notification.error({
        message: e.response.data.errors.map((el: any) => el.message).join(","),
      });
    }
  };

  return (
    <>
      <div className="bg-[#08787f] p-5 text-white font-bold">
        Customer Support Portal
      </div>
      <div className="p-10 bg-white">
        <Form layout="vertical" onFinish={login}>
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Login code" name={"login_code"} required={true}>
            <Input />
          </Form.Item>

          <Button
            className="!text-white !bg-red-400 !border-0"
            loading={loading}
            htmlType="submit"
            size="large"
          >
            Login
          </Button>
        </Form>
      </div>

      {/* {businesses.map((el: any) => (
        <h1> {el.business_id.business_name}</h1>
      ))} */}
    </>
  );
};

export default Login;
