import { Button } from "antd";

const Updates = () => {
  return (
    <>
      <div className="p-10">
        <div className="text-[20px] font-bold">
          Customer support portal Download:
        </div>
        <br />
        <br />
        <div className="bg-green-100 p-10 ">
          Latest Version, 1.0:{" "}
          <a href="https://cdn.dynocrm.com/dynocsp10.exe">
            <Button className="!bg-green-500 !text-white">
              Download for windows
            </Button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Updates;
